import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ComponentTextblock1 from "../../components/component-textblock-1";
import ComponentTextblock2 from "../../components/component-textblock-2";
import ComponentTextblock5 from "../../components/component-textblock-5";
import SectionP60 from "../../components/section/section-p60";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import FooterCta from "../../components/footer-cta";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const PageCompanyAbout = ({ data, location }) => {
  const intl = useIntl();
  return (
    <Layout location={location} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Hybrid Assistive Limb HAL: Neurologically controlled wearable cyborg`
            : `Hybrid Assistive Limb: Neurologisch kontrollierter tragbarer Cyborg`
        }
        description={
          intl.locale === "en"
            ? `Revolutionizing wearable robots industry, HAL is the World first neurologically controlled cyborg. Regenerate physical functions for people with brain-neuromuscular diseases.`
            : `Revolution tragbarer Roboter. HAL ist der weltweit erste Cyborg seiner Art. Er regeneriert körperliche Funktionen von Menschen mit neuromuskulären Erkrankungen.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/1ZPlNtONGPY2No1bGFLaxG/cd9dc4798ff3ec20f864bea82faef66b/E185_190.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect sect_PageMission_catch sect-bg-bls">
        <div className="container">
          <ComponentTextblock1
            title={
              intl.locale === "en"
                ? "Center for Neuro-robotics treatment"
                : "Zentrum für Neurorobotales Training"
            }
            subtitle={intl.locale === "en" ? "About Us" : "Über uns"}
            content=""
          />
          <SectionP60>
            <ComponentTextblock2>
              {intl.locale === "en"
                ? "Our revolutionary technology is a game-changer of what we have as medical experts and neuro-rehabilitation professionals"
                : "Unsere revolutionäre Technologie ist bahnbrechend für medizinische Experten und Fachkräfte in der neurologischen Rehabilitation"}
            </ComponentTextblock2>
            {/* <ComponentTextblock2>{intl.formatMessage({ id: "company.about.message2" })}</ComponentTextblock2>
            <ComponentTextblock2>{intl.formatMessage({ id: "company.about.message3" })}</ComponentTextblock2> */}
          </SectionP60>
          <SectionP60>
            <div className="flex-row justify-content-between">
              <div className="flex-column-6">
                <h3>
                  {intl.locale === "en"
                    ? "We are representing Cyberdyne Inc. and all of our stakeholders in the EU"
                    : "Wir sind der Knotenpunkt der Cyberdyne Inc. und all unseren Beteiligten in der EU"}
                </h3>
                <p>
                  {intl.locale === "en"
                    ? `Cyberdyne Care Robotics GmbH based in Bochum, North
  Rhine-Westphalia, was founded in August 2013. The principal
  shareholder is Cyberdyne Inc. Japan. The company has primarily
  3 business domains:`
                    : `Die Cyberdyne Care Robotics GmbH mit Sitz in Bochum, Nordrhein-Westfalen, wurde im August 2013 gegründet. Der Hauptgesellschafter ist die Cyberdyne Inc. Japan. Das Unternehmen hat drei Geschäftsbereiche: `}
                </p>

                <ComponentTextblock5>
                  {intl.locale === "en" ? "HAL Treatment" : "HAL-Therapie"}
                </ComponentTextblock5>
                <p className="message-sm mb-5">
                  {intl.locale === "en"
                    ? `The company provides HAL treatment for individuals with
  brain-neuromuscular diseases, utilising various types of HAL
  systems to improve their ability to move on their own. The
  company also offers NeuroHALFIT, a neurological fitness
  program utilising the HAL Lumbar type`
                    : `Das Unternehmen bietet HAL-Behandlungen für Menschen mit Hirn- und neuromuskulären Erkrankungen an, wobei verschiedene Arten von HAL-Systemen eingesetzt werden, um deren Fähigkeit sich selbstständig zu bewegen, zu verbessern. Das Unternehmen bietet auch NeuroHALFIT an, ein neurologisches Fitnessprogramm, bei dem der HAL-Lumbaltyp verwendet wird.`}
                </p>
                <ComponentTextblock5>
                  {intl.locale === "en" ? `Products` : `Produkte`}
                </ComponentTextblock5>
                <p className="message-sm mb-5">
                  {intl.locale === "en"
                    ? `The company rents and sells products of Cyberdyne Inc.,
  including the HAL system in the European region. As the
  European headquarters, the company runs the network of
  distributors for Cyberdne Inc. The company also serves as a
  showroom, welcoming visitors ranging from patients and their
  family members to medical professionals, researchers, and
  medical distributors.`
                    : `Das Unternehmen vermietet und verkauft Produkte von Cyberdyne Inc. einschließlich des HAL-Systems in Europa. Vom europäischen Hauptsitz aus betreibt das Unternehmen das Vertriebsnetz von Cyberdne Inc. Es dient zudem als Ausstellungsraum für Besucher,  Patienten und ihre Familienangehörige sowie medizinische Fachkräfte, Wissenschaftler und Fachhändler für Medizintechnik.`}
                </p>
                <ComponentTextblock5>
                  {intl.locale === "en" ? `Point of contact` : `Anlaufstelle`}
                </ComponentTextblock5>
                <p className="message-sm">
                  {intl.locale === "en"
                    ? `The company serves as a point of contact for University
  hospitals and other research institutions for potential
  research collaborations in order to further develop other
  medical applications of the HAL system, including expanding
  its target patient population.`
                    : `Das Unternehmen dient als Anlaufstelle für Universitätskliniken und andere Forschungseinrichtungen und für potenzielle Forschungskooperationen, um medizinische Einsatzgebiete des HAL-Systems weiterzuentwickeln und dadurch auch die Patientenzielgruppe zu vergrößern.`}
                </p>
              </div>
              <div className="flex-column-3">
                <div>
                  <StaticImage
                    src="../../images/about/image-about-us-1.png"
                    className="mb-4"
                    alt=""
                  />
                </div>
                <div>
                  <StaticImage
                    src="../../images/about/image-about-us-2.png"
                    className=""
                    alt=""
                  />
                </div>
              </div>
            </div>
          </SectionP60>
        </div>
      </section>

      <StaticImage
        src="../../images/about/DSC_9672_T_2.jpg"
        className="sect_Product__catch___img2"
        alt=""
      />

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 txt txt-rot mb-5">
            {intl.locale === "en"
              ? `Learn more about us and the Cybernics Technology`
              : `Erfahren Sie mehr über uns und die Cybernics Technologie`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Cyberdyne Inc.` : `Cyberdyne Inc.`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The significant existence of technology is to support, assist,
  enhance, and regenerate functions of humans.`
                    : `Die entscheidende Aufgabe der existierende Technologie ist die menschliche Funktion zu unterstützen, verbessern und regenerieren.`}
                </p>
                <Link to="/company/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Vision and Mission`
                    : `Vision und Auftrag`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `We envision a future where humans and technology are
  seamlessly interfaced, feeding off of each other.`
                    : `Wir stellen uns eine Zukunft vor, in der Menschen und Technologie nahtlos miteinander verbunden sind und sich gegenseitig unterstützen.`}
                </p>
                <Link to="/company/mission/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Cybernics</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `“Cybernics” (adjective: Cybernic) is a new academic field that
  is centered around cybernetics, mechatronics and informatics.`
                    : `“Cybernics" (Adjektiv: cybernic) ist ein neues akademisches Feld, das sich mit Kybernetik, Mechatronik und Informatik beschäftigt.`}
                </p>
                <Link to="/company/cybernics/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Contact/Access` : `Anfahrt`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Cyberdyne Care Robotics GmbH based in Bochum, North
  Rhine-Westphalia. We welcome people from all over the world.`
                    : `Die Cyberdyne Care Robotics GmbH hat ihren Sitz in Bochum, Nordrhein-Westfalen. Wir begrüßen Menschen aus der ganzen Welt.`}
                </p>
                <div>
                  <Link to="/company/access/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <FooterCta />
    </Layout>
  );
};

export default PageCompanyAbout;
